import { useEffect, useState } from 'react';
import { Orientation } from '@/atoms/enums';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { ManagerDimensions } from '@/molecules/chartElements';
import { ManagerSpanBarChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { BarChart } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const metricOptions = [
  EntityMetric.SPAN,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.BUDGET,
];

const ManagerSpans = ({ levelType }) => {
  const [metric, setMetric] = useState(metricOptions[0]);
  const [dimension, setDimension] = useState(ManagerDimensions[levelType]?.[0]);
  const [sort, setSort] = useState({
    metric: metricOptions[0],
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();

  const { chartData, metricTotals } = useAggregateChartData({
    excludeUnmanaged: true,
    entityType: EntityType.ROLE,
    dimension,
    filter,
    includeEmptySpans: true,
    sort,
  });

  const hasRoles = metricTotals?.roles > 0;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title="Manager spans"
      InfoComponent={<ManagerSpanBarChartInformation />}
      ControlsComponent={
        hasRoles && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={ManagerDimensions[levelType]}
              label="Manager span by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              options={metricOptions}
              onChange={handleMetricTypeChange}
            />
          </Stack>
        )
      }
    >
      {hasRoles ? (
        <BarChart
          chartData={chartData}
          excludeId="noManager"
          id="manager-spans-chart"
          metric={metric}
          metricTotals={metricTotals}
          sort={sort}
          orientation={Orientation.VERTICAL}
        />
      ) : (
        <EmptyInsights message="No managed roles were found" />
      )}
    </ChartCard>
  );
};

export default ManagerSpans;
