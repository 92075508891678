import { useEffect, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import {
  usePropertyContext,
  useScenarioContext,
  useTagContext,
} from '@/shared/providers';
import { mapEntities } from '@/shared/utils';

const getFilterIds = (filter) => {
  return filter?.ids?.size ? [...filter.ids] : [];
};

const useAggregateEntities = ({
  entityType = EntityType.ACTIVITY,
  excludeUnmanaged,
  filter,
  includeEmptySpans,
  matrix,
  order,
}) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { tagMap } = useTagContext();
  const { propertyMap } = usePropertyContext();

  const [entities, setEntities] = useState();
  const scopeFilter = filter[ScopeType.TAGS] ?? filter[ScopeType.ACTIVITIES];
  const ids = getFilterIds(scopeFilter);

  useEffect(() => {
    if (!scenario?.entity || !tagMap.size) {
      return;
    }

    const entities = mapEntities({
      entityType,
      excludeUnmanaged,
      filter: scopeFilter,
      includeEmptySpans,
      matrix,
      order,
      propertyMap,
      relationshipMap: scenario?.relationships,
      snapshotEntityMap,
      tagMap,
    });

    setEntities({ ...entities });
  }, [
    scenario?.entity?.uuid,
    scenario?.entity?.__uuid,
    scenario?.entity?.updated_at,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    JSON.stringify(matrix),
    tagMap,
    order,
    JSON.stringify(scopeFilter),
    JSON.stringify(ids),
  ]);

  return {
    entities,
  };
};

export default useAggregateEntities;
