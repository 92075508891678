import { EntityMetric } from '@/lib/enums';

const MetricProperty = Object.freeze({
  [EntityMetric.ACTIVITIES]: {
    label: {
      singular: 'Activity',
      plural: 'Activities',
    },
  },
  [EntityMetric.AVERAGE_SPAN]: {
    label: {
      short: 'Av. Span',
      singular: 'Span average',
      plural: 'Span average',
    },
    formatOptions: {
      style: 'decimal',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.BUDGET]: {
    label: {
      singular: 'Budget',
      plural: 'Budget',
    },
    formatOptions: {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
    },
  },
  [EntityMetric.ENTITY_PERCENTAGE]: {
    label: {
      singular: '% of total team/role hours',
      plural: '% of total team/role hours',
    },
    formatOptions: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.FTE]: {
    label: {
      singular: 'FTE',
      plural: 'FTE',
    },
    formatOptions: {
      style: 'decimal',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.GROUPS]: {
    label: {
      singular: 'Team',
      plural: 'Teams',
    },
  },
  [EntityMetric.HOURS]: {
    label: {
      singular: 'Hour',
      plural: 'Hours',
    },
  },
  [EntityMetric.HOURS_PERCENTAGE]: {
    label: {
      singular: 'Of hours',
      plural: 'Of hours',
    },
    formatOptions: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.MANAGERS]: {
    label: {
      singular: 'Manager',
      plural: 'Managers',
    },
  },
  [EntityMetric.PERCENTAGE]: {
    label: {
      singular: 'Percentage',
      plural: 'Percentages',
    },
    formatOptions: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.RELATIVE_PERCENTAGE]: {
    label: {
      singular: '% relative to parent in chart',
      plural: '% relative to parent in chart',
    },
    formatOptions: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.OF_HOURS]: {
    label: {
      singular: 'Of hours',
      plural: 'Of hours',
    },
    formatOptions: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
  },
  [EntityMetric.ROLES]: {
    label: {
      singular: 'Role',
      plural: 'Roles',
    },
  },
  [EntityMetric.SPAN]: {
    label: {
      singular: 'Span',
      plural: 'Span',
    },
  },
});

export default MetricProperty;
