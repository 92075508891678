import { MetricProperty } from '@/atoms/enums';
import { EntityMetric } from '@/lib/enums';

const MetricOptions = Object.freeze({
  [EntityMetric.ENTITY_PERCENTAGE]: {
    id: EntityMetric.ENTITY_PERCENTAGE,
    label: MetricProperty[EntityMetric.ENTITY_PERCENTAGE].label.singular,
  },
  [EntityMetric.RELATIVE_PERCENTAGE]: {
    id: EntityMetric.RELATIVE_PERCENTAGE,
    label: MetricProperty[EntityMetric.RELATIVE_PERCENTAGE].label.singular,
  },
  [EntityMetric.PERCENTAGE]: {
    id: EntityMetric.PERCENTAGE,
    label: MetricProperty[EntityMetric.PERCENTAGE].label.singular,
  },
  [EntityMetric.HOURS]: {
    id: EntityMetric.HOURS,
    label: MetricProperty[EntityMetric.HOURS].label.plural,
  },
  [EntityMetric.BUDGET]: {
    id: EntityMetric.BUDGET,
    label: MetricProperty[EntityMetric.BUDGET].label.singular,
  },
  [EntityMetric.FTE]: {
    id: EntityMetric.FTE,
    label: MetricProperty[EntityMetric.FTE].label.singular,
  },
  [EntityMetric.AVERAGE_SPAN]: {
    id: EntityMetric.AVERAGE_SPAN,
    label: `${MetricProperty[EntityMetric.AVERAGE_SPAN].label.singular}`,
  },
  [EntityMetric.SPAN]: {
    id: EntityMetric.SPAN,
    label: `${MetricProperty[EntityMetric.SPAN].label.singular} count`,
  },
});

export default MetricOptions;
